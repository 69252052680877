import { EditorPlatformApp } from '@wix/platform-editor-sdk';
import { isHorizontalLayoutEnabled } from './services/experiments';
import { setNewLayoutStyleForProfileWidget } from './services/styles';
import { toMonitored, initializeMonitoring } from './services/monitoring';
import { createAppAPI } from './services/app-api';

export const editorScript: EditorPlatformApp = {
  editorReady: async (editorSDK, _, options) => {
    await initializeMonitoring(editorSDK, options);
    await editorSDK.editor.setAppAPI('', createAppAPI(editorSDK));

    if (!options.firstInstall) {
      return;
    }

    const isADI = options.origin.type === 'ADI';
    const isHorizontalLayout = !isADI && (await isHorizontalLayoutEnabled());

    if (isHorizontalLayout) {
      await toMonitored('setHorizontalLayout', () => {
        return setNewLayoutStyleForProfileWidget(editorSDK);
      });
    }
  }
};
