import { EditorSDK } from '@wix/platform-editor-sdk';
import { SANTA_MEMBERS_APP_DEF_ID } from '../constants/apps';
import { isWidgetInSOSP } from './components';

interface SantaMembersAppApi {
  setSidebarLayout(): Promise<void>;
  setHorizontalLayout(): Promise<void>;
}

const maApiPayload = { appDefinitionId: SANTA_MEMBERS_APP_DEF_ID };

export const createAppAPI = (editorSDK: EditorSDK) => ({
  setSidebarLayout: async (compId: string) => {
    const isCompInSOSP = await isWidgetInSOSP(editorSDK, compId);
    if (isCompInSOSP) {
      const santaMembersApi = (await editorSDK.application.getPublicAPI('', maApiPayload)) as SantaMembersAppApi;
      await santaMembersApi.setSidebarLayout();
    }
  },
  setHorizontalLayout: async (compId: string) => {
    const isCompInSOSP = await isWidgetInSOSP(editorSDK, compId);
    if (isCompInSOSP) {
      const santaMembersApi = (await editorSDK.application.getPublicAPI('', maApiPayload)) as SantaMembersAppApi;
      await santaMembersApi.setHorizontalLayout();
    }
  }
});
