export const PROFILE_CARD_APP_DEF_ID = '14ce28f7-7eb0-3745-22f8-074b0e2401fb';
export const SANTA_MEMBERS_APP_DEF_ID = '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9';

export const PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM = {
  type: 'number' as 'number',
  key: 'profileLayout',
  param: {
    value: 1
  }
};
