import { EditorSDK } from '@wix/platform-editor-sdk';
import { PROFILE_CARD_APP_DEF_ID, PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM } from '../constants/apps';

// In order to make sure the new layouts are coming with correct default app setting, we have to set it from editor script
// As this happens only on install, we always expect only one profile card widget to be installed
export async function setNewLayoutStyleForProfileWidget(editorSDK: EditorSDK) {
  const { applicationId } = await editorSDK.tpa.app.getDataByAppDefId('', PROFILE_CARD_APP_DEF_ID);
  const [tpaCompRef] = await editorSDK.tpa.app.getAllCompsByApplicationId('', applicationId);
  const compRef = await editorSDK.components.getById('', { id: tpaCompRef.id });

  return editorSDK.document.tpa.setStyleParams('', {
    compRef,
    styleParams: [PROFILE_WIDGET_HORIZONTAL_LAYOUT_STYLE_PARAM]
  });
}
